// TYPES
import {AuthSign, LoginData, TokenData} from "../types/CommonTypes";


export function requestData(method: string, data: any, url: string, auth: AuthSign, bodyMsg?: boolean):Promise<Response> {

      return new Promise((resolve, reject) => {

        let requestOptions = {} as any;

        switch (method) {
            case 'POST':
            case 'PUT':
            requestOptions = {
              method: method,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...auth as any
              },
              body: JSON.stringify({})
            };

            if (bodyMsg) requestOptions.body = JSON.stringify(data);
            break;
          case 'GET':
            requestOptions = {
              method: method,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...auth as any
              }
            };
            break;
          default:
        }

        fetch(url, requestOptions)
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject({success: false, msg: error, error: 'NO DATA'});
        });

      });
}

export function TokenVerifyApi(data: TokenData) {

    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + data.token,
            }
          };

        fetch(data.url, requestOptions)
            .then(response => response)
            .then(data => {

                if ( data.ok) {
                  resolve({ login: 'success'});
                } else {
                  resolve({ login: 'error', msg: 'Token Verify fail' });
                }

            })
            .catch(error => {
                console.log("login error")
                reject({ login: 'error', msg: 'Error Login' });
            });

        })
  }

export function LoginApi(data: LoginData) {

    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "username": data.user,
                "password":  data.pwd
            })
        };

        fetch(data.url, requestOptions)
            .then(response => response)
            .then(data => {

                //setTimeout(()=>{
                  if ( data.ok) {
                    let _bearer;
                        _bearer = data.headers.get('Authorization')?.split(' ');
                    let sessionToken

                    if (_bearer) sessionToken = _bearer[1];

                    resolve({ login: 'success', token: sessionToken });
                  } else {
                    resolve({ login: 'error', msg: 'Error / Wrong login or password' });
                  }
                //},5000)


            })
            .catch(error => {
                console.log("login error")
                //setTimeout(()=>{
                  reject({ login: 'error', msg: 'Error Login' });
                //},10000)
            });

        })
  }
